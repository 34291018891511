<template>
  <div class="isDownloading overflow-auto h-[385px]">
    <div
      :id="templateId"
      class="flex flex-col min-w-[500px] min-h-[500px] w-[2940px] bg-white text-black"
    >
      <div class="flex justify-between px-24 pt-12 pb-8">
        <h1 class="text-5xl font-lato w-full">{{ title }}</h1>
        <img class="w-[380px] h-[110px]" :src="BMLogo" alt="benchmarck terminal" />
      </div>
      <div class="relative pl-6 pr-24">
        <slot></slot>
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img
            src="https://res.cloudinary.com/benchmarkminerals/image/upload/c_fill,q_auto,f_auto,w_1800/v1686334298/pro-assets/logo-benchmark_sfu0gz.png"
            class="opacity-20"
            alt="watermark logo"
          />
        </div>
      </div>
      <div
        v-if="toggles"
        class="grid px-24 gap-4 pb-3 max-w-[2940px] w-full justify-start items-start"
        :class="[!footerText ? 'mb-14' : 'mb-3']"
        :style="{ gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))` }"
      >
        <div
          v-for="(toggle, index) in reversedDatasets"
          :key="index"
          class="flex flex-row gap-4 w-full items-center justify-start"
          :class="{
            'border-p-yellow-600 border-b-4 pb-2 col-span-full max-w-[300px]':
              toggle.type === 'line',
          }"
        >
          <div
            class="w-10 h-10 rounded-lg flex-shrink-0"
            :style="{ backgroundColor: toggle.borderColor }"
          ></div>
          <span class="w-full font-lato text-xl font-normal uppercase text-black line-clamp-2">
            {{ toggle.label }}
          </span>
        </div>
      </div>
      <div class="py-10 px-24">
        <div class="font-lato text-xl font-normal uppercase leading-3 text-black w-full">
          {{ downloadImageFooter }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'

const props = defineProps<{
  templateId: string
  title: string
  footerText?: string
  toggles?: Array<{
    label: string
    borderColor: string
    backgroundColor?: string
    type?: string
  }>
  indexHidden?: number[]
}>()

const reversedDatasets = computed(() => {
  return [...props.toggles]
    .map((dataset, index) => ({
      ...dataset,
      isActive: !props.indexHidden.includes(index),
    }))
    .toReversed()
    .filter((dataset) => dataset.isActive)
})

const downloadImageFooter = computed(() => {
  const now = format(new Date(), 'dd/MM/yyyy')

  if (!props.footerText) return `(DOWNLOADED: ${now})`

  return `SOURCE: ${props.footerText} (DOWNLOADED: ${now})`
})
</script>
